<template>
  <div class="page__wrapper">
    <ag-grid
      ref="invoiceGrid"
      pagination
      edit-type="fullRow"
      style="width: 100%; height: 100%"
      :column-defs="columnDefs"
      :row-data.sync="tableData"
      :pagination-page-size="tablePageInfo.pageSize"
      :framework-components="frameworkComponents"
      :grid-options="gridOptions"
    >
      <!-- 查询条件 -->
      <simple-form
        v-model="searchModel"
        :form-field="searchFormField"
      >
        <template slot="tableOperation">
          <el-button @click="handleDataSearch">
            {{ $t("operation.search") }}
          </el-button>
          <el-button
            v-permission="'dealer_invoice:templateDownload'"
            type="primary"
            @click="handleTemplateClick"
          >
            {{ $t("operation.templateDownload") }}
          </el-button>
          <el-button
            v-permission="'dealer_invoice:import'"
            type="primary"
            @click="isShowUploader = true"
          >
            {{ $t("operation.import") }}
          </el-button>
          <el-button
            v-permission="'dealer_invoice:export'"
            type="primary"
            @click="handleExportClick"
          >
            {{ $t("operation.export") }}
          </el-button>
          <el-button
            v-permission="'dealer_invoice:add'"
            type="primary"
            @click="handleAddClick"
          >
            {{ $t("operation.create") }}
          </el-button>
        </template>
      </simple-form>
    </ag-grid>

    <!-- 文件上传 -->
    <!-- <simple-upload
      ref="uploader"
      url="/jlr/tt/invoice/fileUpload"
      :show-flag.sync="isShowUploader"
      @on-success="handleUploadSuccess"
    /> -->
    <simple-upload
      ref="uploader"
      url="/download/tt/invoice/fileUpload"
      :show-flag.sync="isShowUploader"
      @on-success="handleUploadSuccess"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import pageMixins from '@/components/base/simple-table/mixin'

const BASEURL = {
  list: '/jlr/tt/invoice/list',
  save: '/jlr/tt/invoice/save',
  update: '/jlr/tt/invoice/update',
  delete: '/jlr/tt/invoice/delete',
  export: '/jlr/tt/invoice/export',
  template: '/jlr/tt/invoice/template'
}

/** 自定义操作列 */
const Operation = Vue.extend({
  inject: ['handleDataSearch', 'handleDeleteClick', 'handleSaveClick'],
  template: `<div style="height:100%;text-align:center;">
      <el-tooltip effect="light" :content="$t('operation.save')">
        <el-button type="text" icon="el-icon-circle-check" style="font-size:14px;" v-permission="'dealer_invoice:save'"
          @click="handleSaveClick(params)" />
      </el-tooltip>
      <el-tooltip effect="light" :content="$t('operation.cancel')">
        <el-button type="text" icon="el-icon-refresh-left" style="font-size:14px;" @click="handleDataSearch"
          v-permission="'dealer_invoice:save'" />
      </el-tooltip>
      <el-tooltip effect="light" :content="$t('operation.delete')">
        <el-button type="text" icon="el-icon-delete" style="font-size:14px;" v-permission="'dealer_invoice:delete'"
          @click="handleDeleteClick(params.data)" :disabled="params.data.operationRole!=='tt_operation_role_2'" />
      </el-tooltip>
    </div>`
})

export default {
  name: 'DealerInvoice',
  mixins: [pageMixins],
  data () {
    return {
      tableData: [],
      searchModel: { period: this.$moment().subtract(1, 'months').format('YYYYMM') },
      gridOptions: {},
      isDealerCreate: true,
      isShowUploader: false,
      frameworkComponents: {},
      isShowImportDialog: false
    }
  },
  provide () {
    return {
      handleDataSearch: this.handleDataSearch,
      handleSaveClick: this.handleSaveClick,
      handleDeleteClick: this.handleDeleteClick
    }
  },
  computed: {
    isDealer () {
      return this.$store.state.app.userInfo.roleList.find(item => { return item.code === 'dealer' })
    },
    searchFormField () {
      return [
        {
          prop: 'period',
          type: 'Datepicker',
          col: { xs: 4, sm: 4, md: 4 },
          label: this.$t('dealer_invoice.period'),
          component: { type: 'month', valueFormat: 'yyyyMM', clearable: false }
        },
        {
          prop: 'dealerCode',
          type: 'Input',
          labelWidth: '90px',
          col: { xs: 4, sm: 4, md: 4 },
          component: { clearable: true },
          hidden: this.isDealer,
          label: this.$t('dealer_invoice.dealerCode')
        },
        // {
        //   prop: 'vin',
        //   type: 'Input',
        //   col: { xs: 4, sm: 4, md: 4 },
        //   component: { clearable: true },
        //   label: this.$t('dealer_invoice.vin')
        // },
        {
          slotName: 'tableOperation',
          col: this.isDealer ? { xs: 20, sm: 20, md: 20 } : { xs: 16, sm: 16, md: 16 },
          style: { textAlign: 'right' },
          labelWidth: '0'
        }
      ]
    },
    columnDefs () {
      return [
        {
          headerName: this.$t('dealer_invoice.period'),
          field: 'period',
          editable: params => params.data.operationRole !== 'tt_operation_role_1',
          type: 'DatePicker',
          cellEditorParams: {
            componentProp: { type: 'month', clearable: true },
            rules: { required: true, message: this.$t('validate.isRequired') }
          },
          valueGetter: params => params.data.period ? this.$moment(params.data.period, 'YYYYMM') : null,
          valueSetter: params => {
            params.data.period = params.newValue ? this.$moment(params.newValue).format('YYYYMM') : ''
            return true
          },
          valueFormatter: params => params.value ? this.$moment(params.value, 'YYYYMM').format('YYYY-MM') : '',
          minWidth: 140
        },
        {
          headerName: this.$t('dealer_invoice.type'),
          field: 'type',
          editable: params => params.data.operationRole !== 'tt_operation_role_1',
          type: 'Select',
          cellEditorParams: params => {
            return {
              componentProp: {
                optionList: this.$getDictList('tt_invoice_type').map(item => {
                  return { value: item.value, label: item.label }
                }),
                event: {
                  change: (val) => {
                    this.invoiceTypeChange(val, params)
                  }
                }
              },
              rules: { required: true, message: this.$t('validate.isRequired') }
            }
          },
          valueFormatter: params => {
            return this.$getDictLabel({ type: 'tt_invoice_type', value: params.value })
          },
          minWidth: 160
        },
        {
          headerName: this.$t('dealer_invoice.dealerCode'),
          field: 'dealerCode',
          minWidth: 120
        },
        {
          headerName: this.$t('dealer_invoice.dealerName'),
          field: 'dealerName',
          minWidth: 260
        },
        {
          headerName: this.$t('dealer_invoice.invoiceDate'),
          field: 'invoiceDate',
          editable: params => params.data.operationRole !== 'tt_operation_role_1',
          type: 'DatePicker',
          cellEditorParams: {
            rules: { required: true, message: this.$t('validate.isRequired') }
          },
          valueSetter: params => {
            params.data.invoiceDate = this.$moment(params.newValue).format('YYYY-MM-DD')
            return true
          },
          valueFormatter: params => params.value ? this.$moment(params.value).format('YYYY-MM-DD') : '',
          minWidth: 140
        },
        {
          headerName: this.$t('dealer_invoice.invoiceCode'),
          field: 'invoiceCode',
          editable: params => params.data.operationRole !== 'tt_operation_role_1',
          cellEditorParams: params => {
            const len = params.data.type === 'tt_invoice_type_1' ? 12 : 10
            const msg = this.$t('validate.length', {
              field: this.$t('dealer_invoice.invoiceCode'), len: len
            })
            const validator1 = (rule, value, callback, source, options) => {
              const errors = []
              if (!/^.{12}$/.test(value)) {
                errors.push(new Error(msg))
              }
              callback(errors)
            }
            const validator2 = (rule, value, callback, source, options) => {
              const errors = []
              if (!/^.{10}$/.test(value)) {
                errors.push(new Error(msg))
              }
              callback(errors)
            }
            return {
              rules: [
                { required: true, message: this.$t('validate.isRequired') },
                {
                  validator: (params.data.type === 'tt_invoice_type_1') ? validator1 : validator2
                }
              ]
            }
          },
          minWidth: 200
        },
        {
          headerName: this.$t('dealer_invoice.invoiceNo'),
          field: 'invoiceNo',
          editable: params => params.data.operationRole !== 'tt_operation_role_1',
          cellEditorParams: params => {
            const validator = { len: 8, message: this.$t('validate.length', { field: this.$t('dealer_invoice.invoiceNo'), len: 8 }) }
            return {
              rules: [
                { required: true, message: this.$t('validate.isRequired') },
                params.data.type === 'tt_invoice_type_2' ? {} : validator
              ]
            }
          },
          minWidth: 200
        },
        // {
        //   headerName: this.$t('dealer_invoice.purchaseCo'),
        //   field: 'purchaseUnit',
        //   editable: params => params.data.operationRole !== 'tt_operation_role_1',
        //   cellEditorParams: {
        //     rules: { required: true, message: this.$t('validate.isRequired') }
        //   },
        //   minWidth: 200
        // },
        {
          headerName: this.$t('dealer_invoice.vin'),
          field: 'vin',
          editable: params => params.data.operationRole !== 'tt_operation_role_1',
          cellEditorParams: {
            rules: { required: true, message: this.$t('validate.isRequired') }
          },
          valueFormatter: params => params.value ? params.value.substring(params.value.length - 9, params.value.length) : '',
          minWidth: 200
        },
        {
          headerName: this.$t('dealer_invoice.income'),
          field: 'income',
          editable: params => params.data.operationRole !== 'tt_operation_role_1',
          valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
          valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
          cellEditorParams: {
            rules: [
              { type: 'number', message: this.$t('validate.notNumber') },
              { required: true, message: this.$t('validate.isRequired') }
            ]
          },
          minWidth: 200
        },
        {
          headerName: this.$t('dealer_invoice.taxRate'),
          field: 'taxRate',
          editable: params => params.data.operationRole !== 'tt_operation_role_1',
          valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
          valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
          cellEditorParams: {
            rules: [
              { type: 'number', message: this.$t('validate.notNumber') },
              { required: true, message: this.$t('validate.isRequired') }
            ]
          },
          minWidth: 140
        },
        {
          headerName: this.$t('dealer_invoice.tax'),
          field: 'tax',
          valueFormatter: params => this.$utils.thousandFormat(params.value),
          minWidth: 140
        },
        {
          headerName: this.$t('dealer_invoice.incomeTax'),
          field: 'incomeTax',
          valueFormatter: params => this.$utils.thousandFormat(params.value),
          minWidth: 140
        },
        {
          headerName: this.$t('dealer_invoice.invoiceStatus'),
          field: 'invoiceStatus',
          editable: params => params.data.operationRole !== 'tt_operation_role_1',
          type: 'Select',
          cellEditorParams: {
            componentProp: {
              optionList: this.$getDictList('tt_invoice_status').map(item => {
                return { value: item.value, label: item.label }
              })
            },
            rules: { required: true, message: this.$t('validate.isRequired') }
          },
          valueFormatter: params => {
            return this.$getDictLabel({ type: 'tt_invoice_status', value: params.value })
          },
          minWidth: 160
        },
        {
          headerName: this.$t('dealer_invoice.invoiceConfirmType'),
          field: 'invoiceConfirmType',
          editable: true,
          type: 'Select',
          cellEditorParams: params => {
            return {
              componentProp: {
                optionList: this.$getDictList('tt_invoice_confirm_type').map(item => {
                  return { value: item.value, label: item.label }
                }),
                event: {
                  change: (val) => {
                    this.invoiceConfirmTypeChange(val, params)
                  }
                }
              },
              rules: { required: true, message: this.$t('validate.isRequired') }
            }
          },
          valueFormatter: params => {
            return this.$getDictLabel({ type: 'tt_invoice_confirm_type', value: params.value })
          },
          minWidth: 220
        },
        {
          headerName: this.$t('dealer_invoice.reason'),
          field: 'reason',
          editable: params => params.data.invoiceConfirmType === 'tt_invoice_confirm_type_4',
          cellEditorParams: params => {
            const flag = params.data.invoiceConfirmType === 'tt_invoice_confirm_type_4'
            const rules = flag ? { required: true, message: this.$t('validate.isRequired') } : {}
            return { rules: rules }
          },
          minWidth: 200
        },
        {
          headerName: this.$t('dealer_invoice.operationRole'),
          field: 'operationRole',
          valueFormatter: params => {
            return this.$getDictLabel({ type: 'tt_operation_role', value: params.value })
          },
          minWidth: 160
        },
        {
          headerName: this.$t('field.operation'),
          suppressSizeToFit: true,
          width: 100,
          pinned: 'right',
          cellRenderer: 'Operation'
        }
      ]
    }
  },
  beforeMount () {
    this.frameworkComponents = { Operation: Operation }
  },
  mounted () {
    this.handleDataSearch()
  },
  methods: {
    handleDataSearch () {
      const params = Object.assign({}, this.$utils.parseQueryCondition(this.searchModel, this.searchFormField))
      const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
      params.page = { orderBy: 'invoiceDate,invoiceCode' }
      this.$axios
        .post(BASEURL.list, params)
        .then(resp => {
          const respData = resp.data
          this.tableData = respData.list
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    handleDeleteClick (row) {
      this.$msgbox({
        title: this.$t('tip.warning'),
        message: this.$t('tip.confirmDelete'),
        type: 'warning',
        showCancelButton: true,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            this.$axios
              .post(BASEURL.delete, { id: row.id })
              .then(resp => {
                this.$message({ type: 'success', message: this.$t('tip.deleteSuccess') })
                done()
              })
              .finally(() => {
                instance.confirmButtonLoading = false
              })
          } else done()
        }
      })
        .then(() => {
          this.handleDataSearch()
        })
    },
    handleTemplateClick () {
      const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
      this.$axios
        .post(BASEURL.template, {})
        .then(resp => {
          this.$message({ type: 'success', message: this.$t('tip.exportSuccess') })
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    handleAddClick () {
      this.tableData.push({
        dealerCode: this.$store.state.app.userInfo.company.code,
        dealerName: this.$store.state.app.userInfo.company.name,
        operationRole: 'tt_operation_role_2',
        dealer: { id: this.$store.state.app.userInfo.company.id }
      })
      this.$nextTick(() => {
        this.$refs.invoiceGrid.focusOnCell(this.tableData.length - 1, 'period')
      })
    },
    handleSaveClick (row) {
      this.$refs.invoiceGrid.validate(row, status => {
        if (status) {
          const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
          const submitData = this.$_.cloneDeep(row.data)
          submitData.tax = Number(this.$utils.decimalFormat(this.$utils.accMul(row.data.income, row.data.taxRate), 2))
          submitData.incomeTax = Number(this.$utils.decimalFormat(this.$utils.accAdd(row.data.income, submitData.tax), 2))
          this.$axios
            .post(row.data.id ? BASEURL.update : BASEURL.save, submitData)
            .then(resp => {
              const messages = resp.data.exceptionList
              if (messages && messages.length) {
                let str = ''
                messages.forEach(ele => {
                  str += '<div style="padding:8px;">' + ele + '</div>'
                })
                this.$message({
                  type: 'error',
                  duration: 0,
                  showClose: true,
                  dangerouslyUseHTMLString: true,
                  message: str
                })
                this.gridOptions.api.startEditingCell({ rowIndex: row.rowIndex, colKey: 'vin' })
              } else {
                this.$message({ type: 'success', message: this.$t('tip.saveSuccess') })
                this.handleDataSearch()
              }
            })
            .finally(() => {
              loadingFlag.close()
            })
        }
      })
    },
    handleUploadSuccess () {
      this.isShowUploader = false
      this.handleDataSearch()
    },
    handleExportClick () {
      const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
      const params = Object.assign({}, this.$utils.parseQueryCondition(this.searchModel, this.searchFormField))
      params.page = { orderBy: 'invoiceDate,invoiceCode' }
      this.$axios
        .post(BASEURL.export, params)
        .then(resp => {
          this.$message({ type: 'success', message: this.$t('tip.exportSuccess') })
        })
        .finally(() => {
          loadingFlag.close()
        })
      this.isShowTemplateDialog = false
    },
    invoiceTypeChange (val, params) {
      this.gridOptions.api.stopEditing()
      this.gridOptions.api.setColumnDefs(this.columnDefs)
      this.gridOptions.api.setFocusedCell(params.node.rowIndex, 'type')
      this.gridOptions.api.startEditingCell({ rowIndex: params.node.rowIndex, colKey: 'type' })
    },
    invoiceConfirmTypeChange (val, params) {
      this.gridOptions.api.stopEditing()
      this.gridOptions.api.setColumnDefs(this.columnDefs)
      this.$nextTick(() => {
        this.gridOptions.api.setFocusedCell(params.node.rowIndex, 'invoiceConfirmType')
        this.gridOptions.api.startEditingCell({ rowIndex: params.node.rowIndex, colKey: 'invoiceConfirmType' })
        if (val !== 'tt_invoice_confirm_type_4') {
          params.node.setDataValue('reason', '')
        }
        this.handleSaveClick(params.node)
      })
    }
  }
}
</script>
<style lang="less" scoped>
.page__wrapper {
  width: 100%;
  height: 100%;
}
</style>
